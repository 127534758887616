/*!
 * This file is part of ORY Editor.
 *
 * ORY Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * ORY Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with ORY Editor.  If not, see <http://www.gnu.org/licenses/>.
 *
 * @license LGPL-3.0
 * @copyright 2016-2018 Aeneas Rekkas
 * @author Aeneas Rekkas <aeneas+oss@aeneas.io>
 *
 */

.ory-plugins-content-spacer {
  border: 1px dashed var(--minBlack);
  border-bottom: none;
  position: relative;
}

.ory-plugins-content-spacer-read-only {
  border-color: transparent !important;
}

.ory-plugins-content-spacer > .react-resizable > .react-resizable-handle:before,
.ory-plugins-content-spacer > .react-resizable > .react-resizable-handle:hover:before {
  content: " ";
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 0;
  right: 0;
  cursor: n-resize;
  line-height: 12px;
  font-size: 1.5em;
  height: 24px;
}
